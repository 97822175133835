<template>
  <div class="content">
    <div class="top">
      <div class="title">修改手机号</div>
    </div>
    <van-cell-group inset>
      <van-field v-model="phone" readonly />
      <van-field v-model="sms" center clearable placeholder="验证码">
        <template #button>
          <span v-if="smstext" class="yzm" @click="getyzm">{{smstext}}</span>
          <span v-else class="yzm">倒计时{{time}}秒</span>
        </template>
      </van-field>
      <van-field v-model="upphone" type="number" placeholder="新手机号" />
      <van-field v-model="upsms" center clearable placeholder="验证码">
        <template #button>
          <span v-if="upsmstext" class="yzm" @click="getupyzm">{{upsmstext}}</span>
          <span v-else class="yzm">倒计时{{uptime}}秒</span>
        </template>
      </van-field>
    </van-cell-group>
    <div class="but" @click="updatapass">确认修改</div>
  </div>
</template>

<script>
import { sms, updatePhone } from '@/api/user'
import { Toast } from 'vant'
export default {
  data() {
    return {
      upphone: '',
      upsms: '',
      sms: '',
      phone: '',
      time: 60,
      smstext: '获取验证码',
      uptime: 60,
      upsmstext: '获取验证码'
    }
  },
  created() {
    this.phone = this.$route.query.phone
  },
  methods: {
    async updatapass() {
      const phonereg = 11 && /^((13|14|15|16|17|18|19)[0-9]{1}\d{8})$/
      if (!this.sms) {
        Toast.fail('请填写短信验证码')
      } else if (!this.upphone) {
        Toast.fail('请填写新手机号')
      } else if (!this.upsms) {
        Toast.fail('请填写短信验证码')
      } else if (!phonereg.test(this.upphone)) {
        Toast.fail('手机号格式不正确')
      } else {
        const { data } = await updatePhone({
          newVerifyCode: this.upsms,
          oldVerifyCode: this.sms,
          phone: this.upphone
        })
        if (data.code === 200) {
          Toast.success('修改成功,请重新登录!')
          localStorage.setItem('token', '')
          setTimeout(() => {
            this.$router.push('/login')
          }, 2000)
        } else {
          Toast.fail(data.msg)
        }
      }
    },
    async getyzm() {
      const { data } = await sms({
        phone: this.phone
      })
      if (data.code === 200) {
        this.smstext = ''
        const interval = window.setInterval(() => {
          if (this.time-- <= 0) {
            this.time = 60
            this.smstext = '获取验证码'
            window.clearInterval(interval)
          }
        }, 1000)
      } else {
        Toast.fail(data.msg)
      }
    },
    async getupyzm() {
      const { data } = await sms({
        phone: this.upphone
      })
      if (data.code === 200) {
        this.upsmstext = ''
        const interval = window.setInterval(() => {
          if (this.uptime-- <= 0) {
            this.uptime = 60
            this.upsmstext = '获取验证码'
            window.clearInterval(interval)
          }
        }, 1000)
      } else {
        Toast.fail(data.msg)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  height: 100vh;
  padding-top: 25px;
  box-sizing: border-box;
  .top {
    width: 88%;
    margin-left: 6%;
    margin-bottom: 22px;
    .title {
      font-size: 20px;
      // font-weight: bold;
      color: #424242;
    }
    span {
      font-size: 12px;
      color: #9f9f9f;
    }
  }
  /deep/ .van-cell-group {
    background-color: rgba(0, 0, 0, 0);
    .van-cell {
      background-color: rgba(0, 0, 0, 0);
    }
    .van-field__body {
      line-height: 27px;
    }
    .van-cell::after {
      transform: scaleY(1);
    }
  }
  .yzm {
    color: #f8892f;
  }
  .but {
    border: none;
    outline: none;
    width: 88%;
    margin-left: 6%;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #ffffff;
    background-color: #f8892f;
    font-size: 15px;
    border-radius: 25px;
    margin-top: 40px;
  }
}
</style>
